<template >
    <div class=head>
     
        <div class=wraper> 
                <img class=logo src="../assets/cavelogo.jpg"/> 
                <router-link class=title :to="'/'" >Columbia Imaging and Vision Laboratory</router-link>
                <div>
                    <Navigation/> 
                </div>
        </div>  
    </div>    
</template>
<script>
import Navigation from "./Navigation.vue"
export default {
    name:'Header',
    components: {
        Navigation
    }

}
</script>
<style scoped>

    .logo{
        float:left;
        margin-top:7px;
        margin-left:0.3rem;
        width:110px;
        height: 110px;
    }
    .head{
        background-color: rgb(0, 0, 0);
        height:165px;
        text-align: left;
        min-width: 1650px;
        position: relative;
    }

    .wraper{
        /* float:center; */
        width:1100px;
        align-content: center;
        height:150px;
        margin:auto;
        padding:15px;
    }
    .title{
        margin-top: 1.9rem;
        min-width:60%;
        float:left;
        margin-left:25px;
        text-decoration: none;
        font-size:43px;
        font-weight: 600;
        font-family:'Raleway', sans-serif;;
        color: rgb(255, 255, 255);
    }

</style>